import { cn } from '~/utils/css/css';

import * as LabelPrimitive from '@radix-ui/react-label';
import { type VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';

const labelVariants = cva(
  'leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
  {
    variants: {
      variant: {
        default: 'font-medium',
        muted: 'text-muted-foreground',
        error: 'text-destructive font-normal text-xs',
      },
      size: {
        lg: 'text-base',
        default: 'text-sm',
        sm: 'text-xs',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

interface LabelProps
  extends React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>,
    VariantProps<typeof labelVariants> {
  required?: boolean;
}

const Label = React.forwardRef<React.ElementRef<typeof LabelPrimitive.Root>, LabelProps>(
  ({ className, variant, size, required, ...props }, ref) => (
    <span className={'flex items-center gap-1'}>
      <LabelPrimitive.Root
        ref={ref}
        className={cn(labelVariants({ variant, size }), className)}
        {...props}
      />
      {required && <span className={'text-xs text-muted-foreground'}>*</span>}
    </span>
  ),
);
Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
